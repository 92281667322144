import React from 'react'
import Layout from '../components/layout'
import Blog from '../components/blog'

export default function blogTemplate (props) {
  const { pageContext } = props
  const { title, created, body, tags } = pageContext

  return (
    <Layout>
      <Blog title={title} created={created} body={body} tags={tags} />
    </Layout>
  )
}
